// Ica Form Page

.container-960 {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.ica-hero {
  height: 614px;

  &--wrap {
    display: flex;
    flex-wrap: wrap;
    // height: 614px;
  }

  &::before {
    content: "";
    display: block;
    width: 50%;
    height: 614px;
    position: absolute;
    left: 0;
    top: 130px;
    background: #000;
    z-index: -1;
  }
  &::after {
    background: url("https://www.securecash.com.au/images/ica/ica-hero-bg.jpg")
      no-repeat;
    background-size: cover;
    content: "";
    display: block;
    width: 50%;
    height: 614px;
    position: absolute;
    right: 0;
    top: 130px;
    z-index: -1;
  }

  &--content-left {
    display: flex;
    color: #fff;
    flex-wrap: wrap;
    width: 50%;
    align-content: center;
    height: 614px;

    &__title {
      width: 100%;
      font-size: 64px;
      font-weight: 700;
      line-height: 1.1;
      flex-basis: auto;
    }

    .divider-2 {
      margin-top: 40px;
      background-color: #c7a652;
    }
  }

  &--content-right {
    position: relative;
    width: 50%;

    &__card {
      background: #f2f2f2;
      padding: 96px 70px;
      color: #6e6e6e;
      position: absolute;
      top: 18%;
      right: 0;
      width: 107%;
      box-shadow: 0px 0px 19px -5px #737373;

      p {
        font-size: 16px;
        font-family: "Montserrat Light";
        font-weight: 700;
        line-height: 2em;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.ica-main {
  padding: 100px 0;

  p {
    font-family: "Montserrat Light";
    line-height: 2;
  }

  h3 {
    font-size: 26px;
    font-weight: 700;
  }

  &__title {
    text-align: center;
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  &__hr {
    text-align: center;
    margin: 24px auto 32px !important;
  }

  &--head {
    &__title {
      font-size: 34px;
      font-weight: 700;
      line-height: 1.1;
      text-align: center;
      margin-bottom: 70px;
    }

    &__subtitle {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      line-height: 2em;
      font-family: "Montserrat";
      text-align: center;
      margin-bottom: 40px;
    }

    &__wrap {
      max-width: 960px;
      margin: 0 auto;
    }

    &__text {
      text-align: justify;
      line-height: 2em;
    }
    &__text-top {
      text-align: justify;
      margin-bottom: 40px;
      line-height: 2em;
    }
    &__text-and {
      margin-bottom: 40px;
      text-align: center;
    }
    &__text-last {
      line-height: 2em;
      margin-bottom: 40px;
    }
  }

  &--pricipal-details {
    padding-bottom: 60px;

    &__text {
      margin: 16px 0 22px;
    }
  }

  &--agreement-term {
    padding-bottom: 60px;

    &__title-h4 {
      margin-top: 36px;
    }
  }

  &--deed-of-guarantee {
    padding-bottom: 60px;

    &__title-desc {
      margin: 10px 0 20px;
    }

    &__subtitle {
      margin: 40px 0 20px;
    }
  }

  &--executed-as-a-deed {
    padding-bottom: 60px;
  }

  &--licensing-insurance {
    padding-bottom: 60px;

    &__item {
      margin-bottom: 30px;
    }

    &__item:last-child {
      margin-bottom: 0;
    }
  }

  &--edocket-system {
    padding-bottom: 60px;
    &__video {
      position: relative;
      width: 100%;
      padding: 56.25% 0 0 0;
      margin: 0 0 1.5rem 0;
      clear: both;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &--drivers-conducting-services {
    &__top-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      p {
        margin-bottom: 30px;
      }

      .sample-id-note--mobile {
        display: none;
      }
    }

    &__top-wrap-left {
      width: 70%;
      padding-right: 40px;
    }
    &__top-wrap-right {
      width: 20%;
    }

    &__courier-head {
      display: flex;
      align-items: baseline;
      // justify-content: space-between;

      .courier-item-close {
        border: none;
        background: none;
        font-size: 16px;
        cursor: pointer;
        margin-left: 20px;
        outline: none;

        &:hover {
          color: #505050;
        }

        i {
          z-index: -10;
        }
      }
    }

    &__title {
      text-align: left;
    }

    .ica-main__hr {
      margin: 24px 0 32px !important;
    }

    &__list {
      margin-bottom: 28px;

      li {
        list-style-type: none;
        margin-bottom: 12px;
        // display: flex;
        // align-items: center;
        line-height: 1.7;

        &::before {
          font-family: FontAwesome;
          font-size: 12px;
          color: #c7a652;
          content: "\f111";
          margin-right: 6px;
        }
      }
    }

    &__form-wrap {
      margin-top: 30px;
    }

    &__form-item {
      // display: none;
      margin-top: 34px;
    }

    &__courier-title {
      margin-bottom: 12px;
    }

    &__form-item-initial {
      // margin-bottom: 34px;
    }

    &__input {
      display: none;
    }
  }

  &--main-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 58px;
    position: relative;
  }
}

.ica-scroll {
  border: 1px solid rgb(181, 181, 181);
  padding: 18px 20px;
  margin-bottom: 18px;

  .content-scroll {
    margin-top: 14px;
  }

  &--wrap {
    margin-top: 10px;
    height: 500px;
  }

  &--inner {
    padding-right: 16px;
  }

  &--list {
    font-family: "Montserrat Light";
    list-style: none;
    position: relative;

    p {
      display: block;
      line-height: 2em;
      padding-left: 47px;
      margin-bottom: 30px;
    }
  }

  &--list-no {
    font-family: "Montserrat Light";
    list-style: none;
    position: relative;

    p {
      display: block;
      line-height: 2em;
      padding-left: 47px;
      margin-bottom: 12px;
    }
  }

  &--number {
    color: #c7a652;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    position: absolute;
  }

  &--number-ol {
    color: #c7a652;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    position: absolute;
    padding: 1px 0 0 22px;
  }

  &--title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
    padding-top: 16px;
    text-transform: uppercase;
  }
}

.ica-input-container {
  position: relative;
  margin-bottom: 16px;

  .select-wrap select,
  input {
    width: 100%;
  }

  input,
  select {
    font-size: 14px;
    padding: 8px 12px;
    box-shadow: none;
    font-family: "Montserrat";
    // border: none !important;
  }

  .form-notification {
    z-index: 10;
    color: #000;
    font-size: 14px;
    padding: 10px 14px;
    position: absolute;
    background: #fff;
    left: 2%;
    border: 1px solid #a4a7a9;
    border-radius: 4px;
    line-height: 18px;
    top: 44px;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    display: none;

    &::before {
      content: "";
      position: absolute;
      left: 17px;
      top: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #696464;
    }
    &::after {
      content: "";
      position: absolute;
      left: 17px;
      top: -7px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }

    i {
      color: #dc3545;
      margin-right: 4px;
    }
  }

  &--chkbox {
    .form-notification {
      left: 0;
      top: 52px;
    }
  }

  &--upload {
    border: 1px solid rgb(118, 118, 118);
    max-width: 480px;
  }
}

.ica-form-control {
  label {
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
  }
  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--col-2 {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &--col-3 {
    width: 33.333%;
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &--date {
    width: 400px;
  }
  &--single-half {
    width: 480px;
  }
}

.ica-form-control-chkbox {
  .wf-chkbox {
    display: block;
    position: relative;
    padding-left: 38px;
    margin: 7px 0;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Montserrat Light";
    line-height: 29px;

    .chkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 28px;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }

    &:hover input ~ .chkmark {
      background-color: rgba(199, 166, 82, 0.35);
    }

    & input:checked ~ .chkmark {
      background-color: #c7a652;
    }

    .chkmark::after {
      content: "";
      position: absolute;
      display: none;
    }

    & input:checked ~ .chkmark:after {
      display: block;
    }

    & .chkmark:after {
      left: 8px;
      top: 0px;
      width: 7px;
      height: 18px;
      border: solid white;
      border-width: 0 4px 4px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}

// Notification
.ica-dcs-notification {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(248, 248, 248);
  height: 110px;
  width: 540px;
  padding: 30px 20px;
  border: 2px solid #c6a54b;
  display: none;

  p {
    text-align: center;
    line-height: 1.5;
  }
}

// Button
.btn-dcs {
  font-family: "Montserrat";
  margin-top: 10px;
  background-color: #c6a54b;
  color: #ffffff;
  border: none !important;
  padding: 10px 32px;
  font-size: 16px !important;
  cursor: pointer;
  border-radius: 40px !important;
  outline: none;

  &.btn-dcs-d-none {
    display: none;
  }
}

.btn-main-ica {
  font-family: "Montserrat";
  background-color: #c6a54b;
  color: #ffffff;
  border: none;
  padding: 15px 50px;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  border-radius: 40px;
  outline: none;
}
