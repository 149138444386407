html {
  scroll-behavior: smooth;
}

.btn-welcome-main {
  border: 1px solid #fff;

  font-size: 16px;
  padding: 20px 40px;
  margin-bottom: 30px;
}

.divider-3 {
  height: 4px;
  border: none;
  margin: 23px 0 24px 0;
  width: 120px;
}

.hr-divider-left {
  margin-left: 0 !important;
}

.center-content-divider {
  height: 4px;
  border: none;
  width: 100px;
  border-radius: 5px;
}

.divider {
  background: #fff;
  height: 4px;
  border: none !important;
  width: 100px !important;
  border-radius: 5px !important;
}

.btn-black {
  background: #000;
  border: 2px solid #000;
  color: #fff;
}

.btn-gold-alt {
  background: #c7a652;
  border: 2px solid #c7a652;
  color: #fff;

  &:hover {
  }
}

.btn-location-submit {
  border: none;
  border-radius: 4px;
  font-family: "Montserrat";
  font-size: 16px;
  padding: 20px 24px;
  height: 60px;
  width: 182px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
  }
}

.btn-services {
  padding: 2px 24px;
}

.btn-services-alt {
  padding: 2px 24px;

  &:hover {
    background: #000000;
  }
}

.btn-ty-note-submit {
  border: none;
  border-radius: 4px;
  font-family: "Montserrat";
  font-size: 16px;
  padding: 20px 24px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
  }
}

// Main Hero
.welcome-main-hero {
  height: 547px;
  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--content-left {
    color: #fff;
    padding: 170px 0 153px 18px;
    position: relative;
    width: 50%;

    &__title-sm {
      font-size: 38px;
      font-weight: 300;
      margin-bottom: 3px;
    }

    &__title {
      font-size: 64px;
      font-weight: 800;
    }

    .divider-gold {
      margin: 30px 0 57px;
    }

    &__text {
      font-family: "Montserrat Light";
      line-height: 24px;
      width: 500px;
    }

    .arrow-down {
      position: absolute;
      width: 60px;
      height: 60px;
      background-color: black;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;

      a {
        padding: 18px 25px;
      }
    }
  }

  &--content-right {
    color: #6e6e6e;
    width: 50%;
    position: relative;

    &--card {
      background: #f2f2f2;
      padding: 96px 70px;
      position: absolute;
      top: 15%;
      right: 0;
      width: 107%;
      -webkit-box-shadow: 0px 0px 19px -5px rgba(115, 115, 115, 1);
      -moz-box-shadow: 0px 0px 19px -5px rgba(115, 115, 115, 1);
      box-shadow: 0px 0px 19px -5px rgba(115, 115, 115, 1);

      p {
        font-size: 16px;
        font-family: "Montserrat Light";
        line-height: 2em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__identify-business-img {
      position: absolute;
      right: 0;
      top: 30px;
      width: 106%;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 50%;
    // height: 740px;
    height: 680px;
    position: absolute;
    left: 0;
    top: 65px;
    background: #000;
    z-index: -1;
  }
}

.welcome-form {
  height: 100%;
  width: 600px;
  padding: 24px 0 24px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  background: #1a1a1a;
  text-align: center;
  margin: 0 auto;

  h4 {
    font-size: 26px;
  }

  h3,
  h4,
  h5 {
    color: #fff;
    padding-bottom: 16px;
    text-transform: capitalize;
    font-weight: 400;
  }

  label {
    line-height: 20px;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    margin-top: 24px;
    margin-bottom: 10px;
    width: 80%;
    text-align: left;
  }

  .submit-notif-submitting {
    display: none;
  }

  .submit-notif-success {
    display: none;
  }

  .divider-gold {
    margin: 4px auto 10px;
  }

  &--btn-wrap {
    position: relative;
    margin-top: 42px;
    width: 80%;
    margin: 42px auto 30px;

    .btn-wf-submit {
      background-color: #c6a54b;
      display: block;
      color: #ffffff;
      border: none;
      border-radius: 40px;
      font-family: "Montserrat";
      padding: 15px 12px;
      width: 100%;
      cursor: pointer;
      outline: none;
      font-size: 17px;
      -webkit-appearance: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--form-split {
    display: flex;
    width: 80%;
    margin: 0 auto;

    &-control {
      width: 62%;

      label {
        width: 100%;
      }

      .input-container {
        width: 100%;
      }
    }

    .split-control_post_code {
      width: 34%;
      margin-left: 20px;

      label {
        margin-bottom: 11px;
      }
    }
  }

  .input-container {
    position: relative;

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 3%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      width: 93%;
      line-height: 18px;
      top: 37px;
      display: flex;
      align-items: center;
      display: none;

      i {
        min-width: 0;
        padding: 0 6px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }

    .form-notification-text-area {
      top: 182px;
    }

    .select-wrap {
      position: relative;
      width: 100%;

      &::after {
        font-family: FontAwesome;
        content: "\f107";
        position: absolute;
        top: 11px;
        right: 12px;

        color: #fff;
        pointer-events: none;
      }

      select {
        background: #000;
        color: #fff;
        padding-left: 12px;
        width: 100%;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #fff;
        line-height: 24px !important;
        -webkit-appearance: none;
        -moz-appearance: none;

        option {
          background: #000;
          color: #fff;
        }
      }
    }

    input {
      width: 100%;
      font-size: 14px;
      padding: 8px 12px;
      box-shadow: none;
      font-family: "Montserrat";
      border: none !important;
    }

    select {
      padding: 0px 0px 0px 50px;
      box-shadow: none;
      font-family: "Montserrat";
      height: 38px;
    }

    textarea {
      border: 1px solid #fff;
      margin: 0;
      padding: 8px 12px;
      resize: none;
      height: 180px;
      width: 100%;
      font-size: 14px;
      font-family: "Montserrat";
    }
  }

  .input-container-select {
    border: none;

    .form-notification {
      background: #fff;

      i {
        background: transparent;
        position: relative;
        margin-left: 0;
        padding: 0 12px 0 0;
      }
    }
  }

  .input-container-textarea {
    .form-notification {
      i {
        padding: 0 14px 0 0;
      }
    }
  }

  .wf-chkbox-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .wf-chkbox-container {
    width: 80%;
    margin: 0 auto;
    text-align: left;

    label {
      width: 100%;
    }

    .wf-control-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      .wf-chkbox-wrap {
        width: 50%;

        .wf-chkbox {
          position: absolute;
          left: 0;
        }

        label {
          margin-top: 0;
        }
      }
    }

    .wf-chkbox-footnote {
      color: #a4a7a9;
      font-size: 14px;
      font-style: italic;
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .wf-chkbox-week {
    .wf-control-wrapper {
      .wf-chkbox-wrap {
        width: 33.33%;
      }
    }
  }

  &.dispatch-submitting {
    .dispatch-submit {
      cursor: auto;
      color: #c6a54b;
      transition: all 0.2s ease;
      opacity: 0.8;
    }

    .submit-notif-submitting {
      display: inline;
      color: #fff;
      position: absolute;
      top: 32%;
      left: 50%;
      margin-left: -110px;
      cursor: default;

      & i {
        animation: loading 1000ms linear infinite;
        display: inline-block;
      }

      @keyframes loading {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.dispatch-success {
    .dispatch-submit {
      cursor: auto;
      background: #4bb543;
      color: #4bb543;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }

    .submit-notif-success {
      display: inline;
      color: #fff;
      position: absolute;
      top: 32%;
      left: 50%;
      margin-left: -171px;
      cursor: default;

      & i {
        color: #fff;
        display: inline-block;
      }
    }
  }
}

.forms-quote {
  .chkbox-container {
    position: relative;

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 3%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      width: 93%;
      line-height: 18px;
      top: 94px;
      display: none;

      i {
        min-width: 0;
        padding: 0 6px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
  }

  .input-container-select {
    position: relative;

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 3%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      width: 93%;
      line-height: 18px;
      top: 40px;
      display: none;

      i {
        min-width: 0;
        padding: 0 6px 0 0;
        color: red;
        font-size: 20px;
        background: none;
        position: initial;
        margin: 0;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
  }

  .input-container {
    position: relative;
    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 3%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      width: 93%;
      line-height: 18px;
      top: 37px;
      display: none;

      i {
        min-width: 0;
        padding: 0 6px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }

    i {
      min-width: 50px;

      &::before {
        padding-right: 0;
      }
    }
  }

  .button-controls-container {
    .btn-wf-submit {
      background-color: #c6a54b;
      color: #ffffff;
      border: none !important;
      padding: 15px 50px !important;
      font-size: 17px !important;
      cursor: pointer;
      width: 100%;
      border-radius: 40px !important;
      outline: none;
      -webkit-appearance: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.submit-status {
  .submit-notif-submitting,
  .submit-notif-success {
    font-size: 17px !important;
    display: none;
  }

  .button-controls-container {
    .button-section {
      position: relative;
    }
  }

  &.dispatch-submitting {
    .dispatch-submit {
      cursor: auto;
      color: #c6a54b;
      transition: all 0.2s ease;
      opacity: 0.8;
    }

    .submit-notif-submitting {
      display: inline;
      font-size: 17px !important;
      color: #fff;
      position: absolute;
      top: 32%;
      left: 50%;
      margin-left: -114px;
      cursor: default;

      & i {
        animation: loading 1000ms linear infinite;
        display: inline-block;
      }

      @keyframes loading {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.dispatch-success {
    .dispatch-submit {
      cursor: auto;

      background: #4bb543;
      color: #4bb543;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }

    .submit-notif-success {
      display: inline;
      font-size: 17px !important;
      color: #fff;
      position: absolute;
      top: 32%;
      left: 50%;
      margin-left: -182px;
      cursor: default;

      & i {
        color: #fff;
        display: inline-block;
      }
    }
  }
}

.submit-status-alt {
  .submit-notif-submitting {
    font-size: 16px !important;
    display: none;
  }

  .submit-notif-success {
    font-size: 16px !important;
    display: none;
  }

  .welcome-form--btn-wrap {
    position: relative;
  }

  &.dispatch-submitting {
    .dispatch-submit {
      cursor: auto;
      color: #c6a54b;
      transition: all 0.2s ease;
      opacity: 0.8;
    }

    .submit-notif-submitting {
      display: inline;
      font-size: 16px !important;
      font-family: "Montserrat Light";
      color: #fff;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 50%;
      margin-left: -108px;
      cursor: default;

      & i {
        animation: loading 1000ms linear infinite;
        display: inline-block;
      }

      @keyframes loading {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .hazard-form--btn-wrap {
      position: relative;

      .submit-notif-submitting {
        margin-top: -14px;
      }
    }
  }

  &.dispatch-success {
    .dispatch-submit {
      cursor: auto;

      background: #4bb543;
      color: #4bb543;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }

    .submit-notif-success {
      display: inline;
      font-size: 16px !important;
      font-family: "Montserrat Light";
      color: #fff;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 50%;
      margin-left: -162px;
      cursor: default;

      & i {
        color: #fff;
        display: inline-block;
      }
    }

    .hazard-form--btn-wrap {
      .submit-notif-success {
        margin-top: -14px;
        margin-left: -171px;
      }
    }

    .site-info-form--btn-wrap {
      .submit-notif-success {
        margin-left: -171px;
      }
    }
  }
}

.welcome-info-form {
  .wf-chkbox-container {
    position: relative;

    .wf-form-label {
      font-size: 16px;
      display: block;
      line-height: 20px;
      margin-top: 24px;
      margin-bottom: 10px;
      text-align: left;
    }
    .wf-chkbox-wrapper {
      display: flex;
      flex-wrap: wrap;

      .wf-chkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 3px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-top: 3px;
        padding-top: 4px;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .chkmark {
          position: absolute;
          background: #fff;
          top: 0;
          left: 0;
          height: 28px;
          width: 28px;
          border: 1px solid #bbbbbb;
          border-radius: 4px;
        }

        &:hover input ~ .chkmark {
          background-color: rgba(199, 166, 82, 0.35);
        }

        & input:checked ~ .chkmark {
          background-color: #c7a652;
        }

        .chkmark::after {
          content: "";
          position: absolute;
          display: none;
        }

        & input:checked ~ .chkmark:after {
          display: block;
        }

        & .chkmark:after {
          left: 8px;
          top: 0px;
          width: 7px;
          height: 18px;
          border: solid white;
          border-width: 0 4px 4px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .wf-chkbox-33 {
        margin-top: 10px;
        width: 33.33%;
      }

      .wf-chkbox-50 {
        width: 50%;
      }
    }

    .wf-chkbox-footnote {
      color: #a4a7a9;
      font-size: 14px;
      font-style: italic;
      margin: 8px 0 0 30px;
    }

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 3%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      width: 93%;
      line-height: 18px;
      top: 94px;
      display: none;

      i {
        min-width: 0;
        padding: 0 6px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
  }

  .wf-chkbox-week {
    .form-notification {
      top: 204px;
    }
  }
}

.welcome-info-form-site-risk {
  .label-dot {
    color: #c7a652;
    font-size: 11px;
    position: absolute;
    left: 0;
    padding-top: 4px;
  }

  .wf-chkbox-container {
    position: relative;

    .wf-form-label {
      font-size: 16px;
      display: block;
      line-height: 20px;
      margin: 24px 0;
      text-align: left;
      padding-left: 22px;
      font-family: "Montserrat Light";
      font-weight: 600;
    }
    .wf-chkbox-wrapper {
      margin-left: 27px;

      .wf-chkbox {
        display: block;
        position: relative;
        padding-left: 38px;
        margin: 7px 0;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: "Montserrat Light";
        line-height: 29px;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .chkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 28px;
          width: 28px;
          border: 1px solid #bbbbbb;
          border-radius: 4px;
        }

        &:hover input ~ .chkmark {
          background-color: rgba(199, 166, 82, 0.35);
        }

        & input:checked ~ .chkmark {
          background-color: #c7a652;
        }

        .chkmark::after {
          content: "";
          position: absolute;
          display: none;
        }

        & input:checked ~ .chkmark:after {
          display: block;
        }

        & .chkmark:after {
          left: 8px;
          top: 0px;
          width: 7px;
          height: 18px;
          border: solid white;
          border-width: 0 4px 4px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    .wf-chkbox-footnote {
      color: #5d5e5f;
      font-size: 14px;
      font-style: italic;
      margin: 8px 0 0 10px;
      font-family: "Montserrat Light";
      line-height: 20px;
    }
  }

  .welcome-form--form-control {
    position: relative;

    .wf-form-label {
      font-size: 16px;
      display: block;
      line-height: 20px;
      margin: 24px 0;
      text-align: left;
      padding-left: 22px;
      font-family: "Montserrat Light";
      font-weight: 600;
    }

    .select-footnote {
      color: #5d5e5f;
      font-size: 14px;
      font-style: italic;
      margin: 9px 0 0 10px;
      font-family: "Montserrat Light";
      line-height: 20px;
    }

    .input-container-select {
      width: 94%;

      .select-wrap {
        position: relative;
        width: 54%;

        select {
          width: 100%;
          font-size: 14px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #bbbbbb;
          padding-left: 12px;
          box-shadow: none;
          font-family: "Montserrat";
          color: #000;
          line-height: 24px !important;
          height: 38px;
          max-width: 356px;
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        &::after {
          font-family: FontAwesome;
          content: "\f107";
          position: absolute;
          top: 4px;
          right: 44px;
          color: #000;
          pointer-events: none;
        }
      }
      .form-notification {
        z-index: 10;
        color: #000;
        font-size: 14px;
        padding: 10px 14px;
        position: absolute;
        background: #fff;
        right: -1%;
        border: 1px solid #a4a7a9;
        border-radius: 4px;
        line-height: 18px;
        top: 60px;
        display: flex;
        align-items: center;
        display: none;

        i {
          position: initial;
          background: #fff;
          margin-left: 0;
          min-width: 0;
          padding: 0 8px 0 0;
          color: red;
        }

        &::before {
          content: "";
          position: absolute;
          left: -7px;
          top: 13px;
          width: 0;
          height: 0;
          border-right: 7px solid #808080;
          border-bottom: 7px solid transparent;
          border-top: 8px solid transparent;
        }

        &::after {
          content: "";
          position: absolute;
          left: -6px;
          top: 13px;
          width: 0;
          height: 0;
          border-right: 7px solid #fff;
          border-bottom: 7px solid transparent;
          border-top: 8px solid transparent;
        }
      }
    }
  }

  .welcome-form--btn-wrap {
    width: 70%;
  }
}

// Contact Page

.contact--form {
  .input-select-department-wrapper {
    position: relative;

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      line-height: 18px;
      top: 38px;
      display: flex;
      align-items: baseline;
      display: none;

      i {
        position: initial;
        background: #fff;
        margin-left: 0;
        min-width: 0;
        padding: 0 8px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 114px;
        top: -6px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
  }

  .input-container-contact {
    position: relative;

    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #7b7b7b;
      opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #7b7b7b;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #7b7b7b;
    }

    textarea::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #7b7b7b;
      opacity: 1; /* Firefox */
    }

    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #7b7b7b;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #7b7b7b;
    }

    .form-notification {
      z-index: 10;
      color: #000;
      font-size: 14px;
      padding: 10px 14px;
      position: absolute;
      background: #fff;
      left: 4%;
      border: 1px solid #a4a7a9;
      border-radius: 4px;
      line-height: 18px;
      top: 84px;
      display: flex;
      align-items: baseline;
      display: none;

      i {
        position: initial;
        background: #fff;
        margin-left: 0;
        min-width: 0;
        padding: 0 8px 0 0;
        color: red;
      }

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: -4px;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::after {
        top: 10px;
        border-color: transparent transparent transparent #fff;
        border-width: 10px;
      }

      &::before {
        top: 9px;
        border-color: transparent transparent transparent #000;
        border-width: 11px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 17px;
        top: -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #696464;
      }
      &::after {
        content: "";
        position: absolute;
        left: 17px;
        top: -17px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }

    textarea {
      resize: none;
    }
    .form-notification-textarea {
      top: 158px;
    }
  }

  .input-container-contact--textarea {
    display: inline-block;
    padding-top: 1px;
    width: 100%;
  }

  .input-container-contact-chkbox {
    display: inline-block;
    padding-bottom: 1px;
  }

  .welcome-form--btn-wrap {
    width: 100%;
  }
}

.contact--form-wrap {
  width: 100%;
}

// Modal

.site-modal-outer {
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999999999;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  overflow: hidden;

  .site-modal-inner {
    background: #fff;
    border-radius: 3px;
    width: 824px;
    height: 470px;
  }
}

body.modal-on {
  overflow: hidden;

  .site-info-modal {
    opacity: 1;
    pointer-events: all;
  }
}

body.ty-modal-on {
  overflow: hidden;

  .site-modal-outer {
    opacity: 1;
    pointer-events: all;
  }
}

.business-type-thank-you {
  width: 100%;

  &--card {
    background: #f2f2f2;
    margin: auto;
    max-width: 824px;
    padding: 74px 116px;
    box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
    text-align: center;
    position: relative;

    .icon-large {
      color: #4bb543;
      font-size: 84px;
      margin-bottom: 16px;
    }

    &__text-strong {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    &__text {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    &__btn-wrap {
      display: flex;
      justify-content: space-around;
      padding: 34px 22px 0;
    }

    .ty-modal-close-btn {
      background: transparent;
      border: none;
      color: #000;
      cursor: pointer;
      font-size: 28px;
      position: absolute;
      top: -13px;
      right: -11px;

      &::after {
        content: "";
        height: 17px;
        width: 17px;
        background: #fff;
        display: block;
        border-radius: 8px;
        position: absolute;
        top: 8px;
        right: 4px;
        z-index: -3;
      }

      &:hover {
        color: #2d2d2d;
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

// Form qoute adjustment
.forms-quote {
  .banking {
    p {
      line-height: 24px;
      text-transform: initial;
    }
  }

  .quote-ty-note {
    padding: 0 40px;

    p {
      text-transform: initial;
    }

    .ty-note-list-wrap {
      margin: 0 auto;
      width: 172px;

      ul {
        text-align: left;
        margin-top: 16px;

        li {
          position: relative;

          a {
            color: #c7a652;
            padding-left: 40px;

            &:hover {
              text-decoration: none;
              opacity: 0.8;
            }
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            background-size: contain !important;
            top: -4px;
          }
        }

        li.cash-collection::before {
          background: url("/images/contentpageicons/cashcollection.png")
            no-repeat;
          // margin-left: -80px;
        }
        li.cash-delivery::before {
          background: url("/images/contentpageicons/cashdelivery.png") no-repeat;
          // margin-left: -75px;
        }
        li.cash-counting::before {
          background: url("/images/contentpageicons/cashcounting.png") no-repeat;
          // margin-left: -74px;
        }
      }
    }
  }
}

//  quote form page
#quote-content {
  #quote-content-right {
    overflow: visible;

    .quote-page-form {
      margin-top: 18px;
    }
  }
}

.welcome-info-form {
  .welcome-form--form-split {
    .split-control_post_code {
      .input-container {
        .form-notification {
          padding: 6px;

          i {
            padding: 0 14px 0 0;
          }
        }
      }
    }
  }
}

.input-container-hidden {
  display: none !important;
}

// to be merged

.about-us-section {
  .section-content {
    margin-bottom: 0;
    padding-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

#video-section {
  max-height: 740px;
  height: 690px;

  div.video-container {
    top: 60px;
  }
}

.home-video-section {
  #video-section {
    max-height: 680px;
    height: 630px;

    div.video-container {
      top: 0px;
    }
  }
}

// Welcome
#welcome-header-text-wrapper {
  h3.prata2 {
    left: -8%;
    margin-left: -50px;
    font-family: "Times New Roman Regular";
  }

  p {
    font-weight: 700;
  }
}

.v-hidden {
  visibility: hidden;
  height: 0;
  margin: 0 !important;
}

#contact-form-section {
  margin-bottom: 10px;
}

// Contact Page Testimonial Section
.contact-testimonial {
  background: url(../images/mainbg-contact.jpg) no-repeat;
  height: 648px;
  margin-bottom: 30px;
  padding-top: 130px;

  &--title-wrap {
    text-align: center;
    margin: 0 auto 14px auto;

    h3 {
      font-size: 32px;
      font-family: "Montserrat Bold";
      line-height: 2em;
    }
    hr {
      margin: 16px auto 24px auto;
      text-align: center;
    }
    p {
      font-family: "Montserrat Light";
      line-height: 2em;
      font-size: 16px;
    }
  }

  &--carousel-container {
    height: 176px;
    position: relative;
    overflow: hidden;
  }

  &--carousel {
    width: 90%;
    margin: 10px auto;

    &__items {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 800%;
      transition: all 0.6s;

      .carousel-item {
        flex-basis: 100%;

        .excerpt {
          margin: 24px auto;
          text-align: center;
          width: 50%;

          p {
            font-family: "Montserrat Light";
            line-height: 1.8em;
            font-size: 16px;
            position: relative;
            quotes: "“" "”";

            &:before {
              color: #c8c8c8;
              content: open-quote;
              font-size: 150px;
              font-family: "Prata";
              left: -68px;
              position: absolute;
              top: 45px;
            }

            &:after {
              color: #c8c8c8;
              content: close-quote;
              font-size: 150px;
              font-family: "Prata";
              right: -66px;
              position: absolute;
              bottom: -68px;
            }
          }
        }
      }
    }

    &-control {
      display: flex;
      position: absolute;
      height: 80px;
      justify-items: center;
      align-items: center;
      left: 50%;
      transform: translate(-50%);

      .carousel-control-left {
        font-size: 30px;
        opacity: 0.25;
      }
      .carousel-control-right {
        cursor: pointer;
        font-size: 30px;
      }

      .carousel-control-divider {
        height: 100%;
        width: 2px;
        background-color: #b9984b;
        margin: 0 20px;
        padding: 0;
        border-radius: 0px;
      }
    }
  }
}

#contact-testimonial-section {
  margin: 120px 0;
}

.contact-map-section {
  background: url(../images/map-bg.jpg) no-repeat;
  height: 500px;
  width: 100%;
}

.team-item-wrapper {
  min-width: 600px;
  margin-right: 62px;
}

// About Us Hero
.about-hero {
  margin-bottom: 66px;

  &--wrapper {
    display: flex;
    position: relative;
  }

  &--content {
    padding: 100px 174px 100px 0;
    width: 37.7%;

    hr.divider-gold {
      margin: 0;
    }

    h1 {
      font-size: 56px;
      line-height: 1.6em;
    }

    p {
      font-family: "Montserrat";
      line-height: 1.4em;
      margin: 8px 0 24px 0;
      font-size: 24px;
    }

    .callback-button {
      margin-top: 0px;
      margin-left: 0;
    }
  }

  &--img-wrap {
    width: 50%;

    img {
      height: auto;
      width: 851px;
    }
  }

  &--banner-wrap {
    position: absolute;
    bottom: -70px;
    width: 100%;
  }
}
