// About Location 1 & 2 | Special Event
.business-type-hero {
	height: auto;

	&::after {
		content: '';
		background: url('/images/welcome/about-location-img.jpg') no-repeat;
		background-size: cover;
		width: 50%;
		height: 680px;
		position: absolute;
		right: 0;
		top: 65px;
		z-index: 1;
	}

	&--content-left {
		padding: 208px 0 188px 18px;
	}

	&--content-right {
		img {
			width: 100%;
			height: 97%;
			width: 100%;
			object-fit: cover;
		}
	}
}

.business-type-dailog-box {
	margin: 140px 0 168px;
	width: 100%;

	&--card {
		background: #f2f2f2;
		margin: auto;
		max-width: 824px;
		padding: 74px 116px;
		box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
		-webkit-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
		-moz-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);

		p {
			font-size: 20px;
			line-height: 26px;
			text-align: center;
		}

		span {
			color: #c7a652;
			font-size: 26px;
			font-weight: 600;
		}

		&__text-strong {
			font-weight: 700;
		}

		&__text {
			margin-bottom: 26px;
		}

		&__btn-wrap {
			display: flex;
			justify-content: space-around;
			padding: 40px 22px 0;
		}
	}
}

.site-info-form {
	background-image: url(../images/mainbg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;

	margin: 150px 0;
	position: relative;
	z-index: 1;

	&--wrap {
		align-items: center;
		flex-wrap: wrap;
		display: flex;
	}

	&--content {
		width: 50%;
		padding-right: 46px;

		&__title {
			font-weight: 700;
			font-size: 26px;
			line-height: 34px;
			padding-right: 28px;
		}

		.divider-2 {
			margin: 22px 0;
		}

		&__text {
			font-family: 'Montserrat Light';
			line-height: 2em;
		}
	}

	&--form {
		width: 50%;
	}

	&--form-wrapper {
		height: 764px;
	}
}

.welcome-info-form {
	padding-top: 32px;

	.form-slider {
		// .form outer
		overflow: hidden;
		width: 100%;

		.form-slides {
			// form
			display: flex;
			width: 300%;

			.form-slide {
				// page
				width: 33.33%;
				transition: margin-left 0.3s ease-in-out;
				position: relative;

				&__title {
					color: #fff;
					font-size: 26px;
					font-weight: 400;
					padding-bottom: 16px;
					margin-top: 10px;
					text-transform: capitalize;
				}

				&__title-mt-adjust {
					margin-top: 60px;
				}
				&__title-pg2mv-adjust {
					margin-top: 38px;
				}

				.form-slide-btn-wrap {
					button {
						background: transparent;
						border: none;
						color: #fff;
						font-family: 'Montserrat';
						font-size: 14px;
						position: absolute;
						top: 10px;
						left: 38px;
						outline: none;
					}
				}
			}
		}
	}
}

.special-event-hero {
	height: auto;

	&--content-left {
		width: 50%;
		padding: 160px 50px 151px 18px;

		&__title {
		}
	}

	&--content-right {
		width: 50%;
		img {
			width: 100%;
			height: 96%;
		}
	}

	&::after {
		content: '';
		background: url('/images/welcome/special-event-img.jpg') no-repeat;
		background-size: cover;
		width: 50%;
		height: 680px;
		position: absolute;
		right: 0;
		top: 65px;
		z-index: 1;
	}
}

.welcome-info-form-site-risk {
	padding-top: 0;
}

.special-event--wrapper {
	height: 710px;

	.welcome-info-form {
		.form-slides .form-slide__title-mt-adjust {
			margin-top: 50px;
		}

		.form-slides .form-slide__title-pg2mv-adjust {
			margin-top: 10px;
		}

		.form-slides .form-slide__title-pg3mv-adjust {
			margin-top: 16px;
		}
	}
}

.form-site-risk-area {
	margin-bottom: 150px;

	&--left {
		height: 100%;
		width: 30%;
		background: #c7a652;
		position: absolute;
		left: 0;
	}

	&--wrap {
	}

	&--content {
		padding-right: 70px;

		&__subtitle {
			font-size: 16px;
			font-family: 'Montserrat Light';
			line-height: 2em;
		}

		.content-scroll {
			height: 432px;
			padding-right: 42px;
		}
	}
}

.special-events-risk-area {
	&__notes {
		margin-top: 28px;

		h4 {
			text-align: center;
			margin-bottom: 10px;
		}

		p {
			font-size: 16px;
			font-family: 'Montserrat Light';
			line-height: 2em;

			strong {
				text-transform: uppercase;
			}
		}
	}
}
