html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Homepage
.main-content-scroll {
	height: 386px;
	overflow: auto;
	height: 390px;
	--scrollbarBG: #ebebeb;
	--thumbBG: #808080;
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
	direction: rtl;
	background: #fff;
	padding-left: 40px;
	padding-right: 40px;
}

// .webp {
// 	.icon-quote {

// 		&:hover {
// 			background: none !important;
// 			filter: invert(100%);
// 		}
// 	}
// }

.active,
.dot:hover {
	transform: scale(1.34);
	height: 15px;
	width: 15px;
}

.contact-testimonial {
	.bullet-dots-container {
		position: absolute;
		list-style: none;
		padding-bottom: 24px;
		left: 50%;
		transform: translate(-50%);
		display: flex;
		z-index: 1000;
		display: none;

		.bullet-dot {
			background: #f1f1f1;
			width: 16px;
			height: 16px;
			margin: 0 3px;
			border: 2px solid #bbbbbb;
			border-radius: 50%;
			opacity: 0.5;
			cursor: pointer;
		}

		.bullet-dot.active-dot {
			opacity: 1;
			background-color: #c6a552;
			transform: scale(1.2);
			cursor: initial;
		}
	}
}

#testimonial-carousel {
	.item {
		.content {
			.wrapper {
				margin: auto;

				.excerpt {
					margin: 24px auto;
					text-align: center;
					width: 50%;

					p {
						position: relative;
						quotes: '“' '”';

						&:before {
							color: #c8c8c8;
							content: open-quote;
							font-size: 150px;
							font-family: 'Prata';
							left: -68px;
							position: absolute;
							top: 45px;
						}

						&:after {
							color: #c8c8c8;
							content: close-quote;
							font-size: 150px;
							font-family: 'Prata';
							right: -66px;
							position: absolute;
							bottom: -68px;
						}
					}
				}

				.scroll-control {
					left: 50%;
					margin-left: -44px;
				}
			}
		}
	}
}

// Updated Partner Page
.partner-page {
	padding-left: 20px !important;
	padding-right: 20px !important;
	width: 95% !important;
}

.partners-hero {
	padding-top: 70px;

	&--header {
		h1 {
		}

		hr {
			margin: 30px auto 60px;
		}

		h3 {
			margin-bottom: 40px;
		}

		p {
			line-height: 2em;
			margin-bottom: 36px;
			text-align: left;
		}

		&__img {
		}
	}
}

.partners {
	margin-top: 78px;

	&--flag-wrap {
		margin-top: 52px;
		padding: 0 80px;
		text-align: center;
	}

	&--item {
		margin-top: 48px;

		&__header {
			align-items: center;
			display: flex;

			img {
				margin-right: 20px;
			}

			h3 {
				color: #0000a0;
				font-weight: bolder;
				font-size: 27px;

				.title-em {
					font-style: italic;
				}
			}
		}

		&__partner {
			h4 {
				margin: 40px 0 30px;
			}

			&--article-head {
				text-align: left;
				line-height: 2em;
				margin-right: 20px;
			}

			img {
				float: right;
				margin-bottom: 30px !important;
				margin-left: 24px !important;
			}

			p {
				text-align: left !important;
				line-height: 2em;
			}

			ul {
				margin: 34px 0;
				padding-left: 50px;

				li {
					line-height: 1.8em;
				}
			}

			hr {
				margin-bottom: 44px;
			}
		}

		&__hr {
			background-color: #000;
			height: 2px;
			border: none;
			margin: 30px 0 24px 0;
		}
	}
}

#hero-image {
	.cta-box {
		.btn {
			padding: 2px 24px;
		}
	}
}

// Quote Page Call back cta

.quote-callback-cta-mobile {
	display: none;
}

// #companies1 .slider .slide-track {
// 	width: 100% !important;
// }
