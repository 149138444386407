// Covid Page
.covid-page-intro {
	background: #f4f4f4;
	height: calc(100vh - 130px);

	&--wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: calc(100vh - 130px);
	}

	&--img-wrap {
		width: 40%;

		img {
			width: 100%;
		}
	}

	&--content {
		height: 100%;
		position: relative;
		width: 60%;
	}

	&--content-wrap {
		position: absolute;
		height: 548px;
		width: 998px;
		top: 129px;
		right: 0;
		background: #ffff;
		padding: 60px 100px;
		@include drop-shadow-1;

		.divider-2 {
			margin: 20px 0 26px;
		}

		&__main-title {
			line-height: 44px;
		}
		&__title {
			font-size: 32px;
		}

		&__scroll {
			overflow: auto;
			height: 236px !important;
			/* width: 95%; */
			line-height: 2;
			--scrollbarBG: #ebebeb;
			--thumbBG: #808080;
			scrollbar-width: thin;
			scrollbar-color: var(--thumbBG) var(--scrollbarBG);
			overflow: auto;
			background: #fff;
			margin-top: 16px !important;
			padding-right: 50px;

			p {
				font-family: 'Montserrat Light';
				font-size: 16px;
				line-height: 2em;
				margin-bottom: 16px;
			}
		}
	}
}

.quiz-main-wrap {
	margin: 0 auto;
	max-width: 1130px;
	padding-bottom: 80px;
}

.covid-page-main {
	&--head-img-wrap {
		background: #000;
		height: 534px;
		padding-top: 130px;
		margin-bottom: 194px;

		&__img {
			width: 100%;
			height: auto;
			margin: 0 auto;

			width: 1130px;
		}
	}

	&--content-wrap {
		padding-top: 80px;

		&__title {
			font-family: 'Montserrat Bold';
			font-weight: 700;
			margin: 8px 0 14px;
		}

		p {
			font-family: 'Montserrat Light';
			font-size: 16px;
			line-height: 2em;
			margin-bottom: 16px;
		}
	}

	&--btn-area {
		background: linear-gradient(
				0deg,
				rgba(206, 206, 206, 0.6),
				rgba(206, 206, 206, 0.6)
			),
			url('../../../images/covid/start-quiz-bg.jpg') center;
		padding: 82px 0;
		text-align: center;
	}

	&--form-area-wrap {
		padding-top: 80px;
	}

	&--form-area {
		padding-bottom: 100px;

		&__title {
			font-family: 'Prata';
			font-size: 32px;
			font-weight: 400;
			margin-bottom: 16px;
			line-height: 1.25em;
			text-align: center;
		}

		.divider-custom {
			margin-left: auto;
			margin-right: auto;
		}

		&__subtitle {
			text-align: center;
		}
	}
}

.covid-form-wrap {
	@include drop-shadow-1;
	margin-top: 60px;
	display: flex;

	&--left {
		background: #c7a652;
		width: 25%;
	}
	&--right {
		padding: 100px 120px;
		width: 75%;
	}
}

.covid-main-form {
	fieldset {
		border: none;
		margin-bottom: 26px;

		legend {
			font-size: 16px;
			margin-bottom: 24px;
			font-family: 'Montserrat Bold';
			color: #333333;
			line-height: 24px;
		}

		.radio-control {
			display: block;
			position: relative;
			padding-left: 24px;
			margin-bottom: 12px;
			cursor: pointer;
			font-size: 22px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			display: flex;
			font-family: 'Montserrat';
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;

			& input:hover ~ .radio-btn:after {
				display: block;
			}

			& input:checked ~ .radio-btn {
				background-color: #fff;
				border: 1px solid #c7a652;
			}

			& input:checked ~ .radio-btn:after {
				display: block;
			}

			& .radio-btn:after {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: #c7a652;
			}

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;
			}
		}

		.radio-btn {
			position: absolute;
			top: 2px;
			left: 0;
			margin-top: 1px;
			height: 18px;
			width: 18px;
			border: 1px solid #c7a652;
			border-radius: 50%;

			&:after {
				content: '';
				position: absolute;
				display: none;
			}
		}

		.radio-control {
			display: flex;
			align-items: center;
			margin-bottom: 16px;

			label {
				margin-left: 6px;
			}
		}
	}

	&--notif-wrap {
		position: relative;

		&__err-notif {
			position: absolute;
			top: 16px;
			top: 16px;
			padding: 12px 12px 12px 32px;
			background: #fff;
			border: 1px solid #9c9c9c;
			border-radius: 4px;
			line-height: 21px;

			&:before {
				content: '\f06a';
				font-family: 'FontAwesome';
				color: #dc3545;
				font-size: 18px;
				font-weight: 400;
				position: absolute;
				left: 12px;
				top: 13px;
			}

			strong {
				font-weight: 600;
			}
		}
	}
}

.btn-covid {
	align-items: center;
	background-color: #c7a652;
	border: 1px solid #c7a652;
	border-radius: 50px;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 16px;
	padding: 21px 48px;
	cursor: pointer;
	outline: none;

	&:hover {
		color: #c7a652;
		background: #fff;
		border: 1px solid #c7a652;
		opacity: 1;
	}
}

.page-hero {
	height: 616px;

	&--wrap {
		display: flex;
	}

	&--content-left {
		width: 50%;
		display: flex;
		height: 616px;
		justify-content: center;
		flex-direction: column;

		&__title {
			font-size: 48px;
			width: 480px;
		}
	}

	&--content-right {
		position: relative;
		width: 50%;
	}

	&:after {
		content: '';
		background: url('../../../images/covid/covid-page-hero.jpg') no-repeat;
		background-size: cover;
		width: 50%;
		height: 680px;
		position: absolute;
		right: 1px;
		top: 65px;
		z-index: 1;
	}
}

.quiz-modal {
	&--card {
		background: #f2f2f2;
		margin: auto;
		max-width: 824px;
		padding: 74px 116px;
		box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
		-webkit-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
		-moz-box-shadow: 1px 9px 29px -2px rgba(0, 0, 0, 0.26);
		text-align: center;
		position: relative;

		&__text-strong {
			font-size: 20px;
			font-weight: 600;
			line-height: 30px;
			text-align: center;
			margin-bottom: 1.25rem;
		}

		&__form-wrap {
			width: 592px;
		}

		&__form {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.form-group-certificate-title {
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				max-width: 400px;
				margin: 0 auto 20px;
			}

			.form-group-certificate {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				justify-content: space-between;
			}

			.form-group-certificate-item {
				height: 68px;
				width: 49%;

				.form-notification {
					font-size: 14px;
					margin-top: 2px;
					display: none;

					i {
						color: #dc3545;
					}
				}
			}

			.form-control-certificate {
				width: 100%;
				padding: 16px;
				border: none;
				font-size: 16px;
				font-family: 'Montserrat Light';
				outline: none;
			}

			.btn-certificate {
				align-items: center;
				background-color: #c7a652;
				border: 1px solid #c7a652;
				border-radius: 50px;
				color: #fff;
				font-family: 'Montserrat';
				font-size: 16px;
				padding: 16px 60px;
				cursor: pointer;
				outline: none;

				&:hover {
					color: #c7a652;
					background: #fff;
					border: 1px solid #c7a652;
					opacity: 0.8;
				}
			}
		}
	}

	.icon-large {
		color: #4bb543;
		font-size: 84px;
		margin-bottom: 16px;
	}
}

body.quiz-modal-on {
	overflow: hidden;

	.site-modal-outer {
		opacity: 1;
		pointer-events: all;
	}
}

.quiz-mistake-d {
	display: none;
}

.quiz-certificate-form {
	.submit-notif-submitting,
	.submit-notif-success,
	.submit-notif-success-note,
	.submit-notif-success-back-to-home {
		display: none;
		position: absolute;
	}

	.form-group-certificate-btn-wrap {
		position: relative;
		margin-top: 16px;
		margin-bottom: 10px;
	}

	&.dispatch-submitting {
		.dispatch-submit {
			cursor: auto;
			background: #fff;
			transition: all 0.2s ease;
			opacity: 0.8;

			&:hover {
				color: #fff;
			}
		}

		.submit-notif-submitting {
			display: inline;
			color: #c6a54b;
			position: absolute;
			top: 35%;
			left: 50%;
			margin-left: -60px;
			cursor: default;

			& i {
				animation: loading 1000ms linear infinite;
				display: inline-block;
			}

			@keyframes loading {
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}

	&.dispatch-success {
		.dispatch-submit {
			cursor: auto;
			background: #4bb543;
			color: #4bb543;
			transition: all 0.2s ease;
			border: 1px solid #4bb543;
			pointer-events: none;

			&:hover {
				opacity: 1;
				cursor: default;
			}
		}

		.submit-notif-success {
			display: inline;
			color: #fff;
			position: absolute;
			top: 33%;
			left: 50%;
			margin-left: -43px;
			cursor: default;

			& i {
				color: #fff;
				display: inline-block;
			}
		}

		.form-group-certificate,
		.form-group-certificate-title {
			opacity: 0;
			pointer-events: none;
		}

		.submit-notif-success-note {
			display: inline;
			top: -216%;
			left: 50%;
			margin-left: -194px;
			min-width: 392px;
			font-size: 18px;
		}

		.submit-notif-success-back-to-home {
			display: inline;
			top: -130%;
			left: 50%;
			margin-left: -165px;
			min-width: 330px;
		}
	}
}
