.tnc-page-main {
	background: #f7f7f7;
	margin-top: 150px;
	position: relative;
	z-index: 1;

	&__img-bg {
		height: 100%;
		object-fit: cover;
		position: absolute;
		right: 0;
		width: 30%;
		z-index: -1;
	}

	&--content {
		padding: 110px 0 110px 18px;
		width: 65%;

		&__title {
			font-weight: 700;
		}

		.divider-gray {
			margin-bottom: 44px;
		}

		.content-scroll {
			background: #f7f7f7;
			height: 366px;

			h4 {
				margin-bottom: 18px;
			}
		}

		&__list {
			list-style: none;
			position: relative;
			width: 784px;

			i {
				color: #c7a652;
				font-size: 11px;
				position: absolute;
				left: 0;
				padding-top: 6px;
			}

			p {
				display: block;
				font-family: 'Montserrat Light';
				line-height: 2em;
				padding-left: 47px;
				margin-bottom: 30px;
			}
		}
	}
}

.tnc-page-sa-clauses {
	background: #f7f7f7;
	position: relative;
	z-index: 1;

	&__img-bg {
		height: 100%;
		object-fit: cover;
		position: absolute;
		left: 0;
		width: 30%;
		z-index: -1;
	}

	&--wrap {
		display: flex;
		justify-content: flex-end;
	}

	&--content {
		padding: 74px 18px 84px 0;
		width: 65%;

		&__title {
			font-weight: 700;
		}

		.divider-gray {
			margin-bottom: 30px;
		}

		&__subtitle {
			font-size: 18px;
			margin-bottom: 24px;
			padding-top: 16px;
		}

		.content-scroll {
			background: transparent;
			height: 370px;
			overflow-x: hidden;
		}

		&__list {
			font-family: 'Montserrat Light';
			list-style: none;
			position: relative;
			width: 820px;

			.tnc-number {
				color: #c7a652;
				font-size: 16px;
				font-weight: 600;
				left: 0;
				position: absolute; //
			}

			p {
				display: block;
				line-height: 2em;
				padding-left: 47px;
				margin-bottom: 30px;
			}

			p.bullet-wide {
				padding-left: 62px;
			}
		}

		&--btn-wrap {
			display: flex;
			justify-content: center;
			margin-top: 94px;

			.btn-gold-square {
				background: #c7a652;
				border-radius: 3px;
				color: #fff;
				padding: 18px 56px;

				&:hover {
					background-color: #000;
					text-decoration: none;
				}
			}
		}
	}
}

.tnc-page-sa-form {
	margin: 90px 0 112px;

	&--wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&--content {
		align-self: center;
		padding-left: 18px;

		width: 50%;

		&__title {
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 20px;
			text-transform: uppercase;
			margin-top: 56px;
		}

		&__text-alt {
			span {
				color: #c7a652;
				font-style: italic;
			}
		}

		p {
			font-family: 'Montserrat Light';
			font-size: 16px;
			line-height: 2em;
		}

		.tnc-and {
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.input-container {
		position: relative;
	}

	&--form {
		width: 50%;

		&__text-alt {
			display: none;
			span {
				color: #c7a652;
				font-style: italic;
			}
		}

		&__title {
			font-weight: 600;
			margin-top: 16px;
		}
	}
}
