.austrac-hero {
	&--content-left {
		padding: 204px 0 227px 18px;

		.divider-2 {
			margin: 30px 0 22px;
		}

		p {
			display: none;
		}
	}

	&::after {
		content: '';
		background: url('/images/welcome/indentify-business-hero-img.jpg') no-repeat;
		background-size: cover;
		width: 50%;
		height: 680px;
		position: absolute;
		right: 1px;
		top: 65px;
		z-index: 1;
	}
}

.austrac-main {
	margin: 230px 0 150px;

	position: relative;
	z-index: 1;

	&--wrap {
		align-items: center;
		flex-wrap: wrap;
		display: flex;
	}

	&--content {
		width: 50%;

		&__title {
			font-weight: 700;
			margin-top: 40px;

			span {
				text-transform: uppercase;
			}
		}

		.divider-2 {
			margin: 22px 0;
		}

		p {
			font-family: 'Montserrat Light';
			line-height: 2em;
		}
	}

	&--form {
		width: 50%;

		.form-quote {
			margin-right: 0;
		}
	}
}
