.inner-grid {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
}

.blog-single-hero {
  background: #000;
  color: #fff;
  height: 100%;
  margin-bottom: 44px;
  position: relative;

  img {
    width: auto;
    height: 100%;
  }

  &--wrap {
    display: flex;
    align-items: center;
    position: relative;
  }

  &--head {
    padding: 0;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 340px;

    &__title {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 20px;
      text-transform: initial;
      text-align: center;
    }

    .divider-2 {
      margin: 6px 0 16px;
    }

    &__meta {
      display: flex;

      .blog-meta--date {
        font-style: italic;
        margin-bottom: 0;
      }
    }
  }
}
// Blog Main
.blog-single-main {
  margin-bottom: 100px;

  &--wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &--social {
    width: 18%;
    top: 58px;
    position: absolute;
    z-index: 10;

    &__list {
      list-style: none;
      display: flex;
      align-items: center;
      flex-direction: column;

      li {
        margin-bottom: 26px;

        a {
          background: #f2f2f2;
          border-radius: 50%;
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.15s all ease-in;

          &:hover {
            background: #000;
            color: #fff;
            text-decoration: none;
          }
        }

        i {
          font-size: 24px;
        }
      }
    }
  }
  &--content {
    padding-bottom: 100px;
    margin: 0 auto 70px;
    max-width: 900px;
    position: relative;

    &__feature-img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: 40px auto;
      height: 420px;
      object-fit: cover;
      width: 100%;
    }

    p {
      font-family: "Montserrat Light";
      line-height: 2em;
      margin-bottom: 1.25rem;
    }

    h3 {
      font-family: "Montserrat Bold";
      margin: 38px 0 18px;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
      margin: 26px 0 16px;
    }

    ul {
      margin-left: 28px;
      margin-bottom: 26px;

      li {
        line-height: 2em;
        margin-bottom: 11px;
        font-family: "Montserrat Light";

        &::marker {
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          font-size: 20px;
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 40px auto;
    }

    .content-callout {
      background: #f2f2f2;
      padding: 30px;
      margin: 30px 0;

      p {
        margin-bottom: 0;
      }
    }

    blockquote {
      border-left: 3px solid #1d2b36;
      margin: 35px 0;
      padding: 5px 0 5px 18px;
      font-style: italic;
      line-height: 2em;

      cite {
        display: block;
        font-size: 85%;
        line-height: 1.8;
        margin-top: 0.85em;

        &::before {
          content: "\2014";
          padding-right: 6px;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0%;
      width: 100%;
      border-bottom: 1px solid #d6d6d6;
    }

    .table-source {
      text-align: center;
    }
  }

  // blog post
  &--content-table {
    border: 1px solid #b7b7b7;
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 10px;

    th {
      border: 1px solid #b7b7b7;
      height: 40px;
    }

    td {
      border: 1px solid #b7b7b7;
      line-height: 1.3;
      height: 30px;
      padding: 6px 0;
    }
    &__head {
      width: 100%;
    }

    &__head-entity {
      width: 50%;
    }
    &__head-year {
      width: 10%;
    }
    &__head-records {
      width: 15%;
    }
    &__head-method {
      width: 25%;
    }

    .tr-center {
      text-align: center;
    }
  }

  // Blog Footer
  &--footer {
    &__title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 30px;
    }

    hr {
      margin: 20px auto 10px;
    }

    .blog-index-footer {
      margin-top: 50px;
    }
  }

  &--footer-content {
    display: flex;
    flex-wrap: wrap;
  }

  &--footer-content-item {
    width: 33.33%;
    padding: 0 12px;

    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      transition: 0.2s all ease-in;
    }

    &__img {
      height: 240px;
      margin-bottom: 14px;
      object-fit: cover;
      width: 100%;
    }

    &__title {
      padding: 0 20px;
      height: 56px;
      text-align: center;
      font-size: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.blog-index-hero {
  &--content {
    &__title {
      font-size: 46px;
      font-weight: 800;
      line-height: 50px;
      text-transform: capitalize;
      width: 590px;
    }
    &__subtitle {
      .blog-meta--title {
        font-style: italic;
        margin-bottom: 0;
      }
    }
  }
}

.link-new-tab {
  &:after {
    content: "\f08e";
    font-family: "FontAwesome";
    color: #737373;
    padding-left: 6px;
  }
}

// Blog Index
.blog-index-main {
  margin-bottom: 90px;
  margin-top: 84px;

  &--content {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
  }

  &--content-item {
    width: 33.33%;
    margin-bottom: 38px;
    padding: 0 12px;

    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      transition: 0.2s all ease-in;
    }

    &__img {
      height: 240px;
      margin-bottom: 12px;
      object-fit: cover;
      width: 100%;
    }

    &__title {
      padding: 0 20px;
      height: 56px;
      text-align: center;
      font-size: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
