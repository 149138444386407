@import "./files/utilities.scss";
@import "./files/general.scss";
@import "./files/terms.scss";
@import "./files/austrac.scss";
@import "./files/business-type.scss";
@import "./files/blog.scss";
@import "./files/live-site-adj-style.scss";
@import "./files/covid.scss";
// @import "./files/test-slider.scss";
@import "./files/ica-form.scss";

//Test
.forms-quote-v2 {
  height: 100% !important;
}

@media (max-width: 992px) {
  .forms-quote-v2 {
    height: auto !important;
  }
}
